import React, { forwardRef, useState } from 'react';

import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide } from '@material-ui/core';

import Loading from '../../../../Common/Loading';
import { Form } from '../../../../Common/styled/Form';
import { GET_HOSPITAL_GROUPS_FOR_EDITING } from '../../../../../graphql/queries';
import { CREATE_USER_GROUP_MEMBER } from '../../../../../graphql/mutations';
import { parseGraphqlErrors } from '../../../../../utils/FormikUtils';
import { TextFieldSelectUI } from '../../../../../componentsUI/TextFieldSelect';
import { AlertUI } from '../../../../../componentsUI/Alert';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      padding: 15,
      width: '100%',
      maxWidth: 800,
    },
    '& .MuiTypography-h6': {
      fontWeight: 600,
    },
  },
  form: {
    marginBottom: 24,
  },
  button: {
    margin: '0 10px',
    padding: '8px 25px',
    fontWeight: 700,
  },
  label: {
    color: alpha(theme.palette.text.primary, 0.54),
  },
  helperTextRoot: {
    color: theme.palette.error.main,
  },
}));

const inputStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

export const NewGroupMemberDialog = ({ group, teamMembers, open, onClose }) => {
  const { t } = useTranslation();

  const uuid = useSelector((state) => state.hospital.uuid);
  const groupMembersIds = group && group.groupMembers && group.groupMembers.map((member) => member.user.uuid);
  const memberOptions = teamMembers && teamMembers
    .filter((teamMember) => !groupMembersIds.includes(teamMember.user.uuid))
    .map((teamMember) => ({ value: teamMember.user.uuid, label: `${teamMember.user.name} ${teamMember.user.surname}` }));

  const [updating, setUpdating] = useState(false);
  const [serverError, setServerError] = useState(false);
  const classes = useStyles();
  const inputClass = inputStyles();

  const [createUserGroupMember, { loading }] = useMutation(CREATE_USER_GROUP_MEMBER, {
    onCompleted: () => {
      toast('The user has been added to the group.', { className: 'toast-success' });
      onClose();
    },
    onError: (createUserGroupMemberError) => {
      if (createUserGroupMemberError.graphQLErrors && createUserGroupMemberError.graphQLErrors.length) {
        setServerError(createUserGroupMemberError.graphQLErrors[0].message);
      }
      toast('There was an error adding the user to the group. Please try again.', { className: 'toast-error' });
    },
    refetchQueries: [{ query: GET_HOSPITAL_GROUPS_FOR_EDITING, variables: { uuid } }],
    awaitRefetchQueries: true,
  });

  const handleSubmit = async (form, { setErrors, resetForm }) => {
    setServerError(false);
    setUpdating(true);
    try {
      await createUserGroupMember({
        variables: {
          input: {
            userUuid: form.user,
            userGroupUuid: group.uuid,
          },
        },
      }).then(
        setUpdating(false),
      );
    } catch (errors) {
      const formikErrors = parseGraphqlErrors(errors.graphQLErrors, t);
      if (
        Object.keys(formikErrors).length === 0
        && formikErrors.constructor === Object
      ) {
        setServerError(true);
      } else {
        setErrors(formikErrors);
      }
    }
    resetForm({});
  };

  const closeModal = () => {
    onClose();
  };

  const validationSchema = Yup.object().shape({
    user: Yup.string().required(t('required.field')),
  });

  const initialValues = {
    user: '',
  };

  if (!memberOptions) return null;

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle>{t('add.member')}</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <Form autoComplete="off" id="addGroupMember" className={classes.form}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  {memberOptions && memberOptions.length ? (
                    <TextFieldSelectUI
                      name="user"
                      label={t('user')}
                      props={props}
                      options={memberOptions}
                      classes={inputClass}
                    />
                  ) : <div>{t('no.available.members.to.add')}</div>}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                {serverError && <AlertUI severity="error" title="Error">{t('server.error')}</AlertUI>}
              </Grid>
            </Form>
          )}
        </Formik>
        {(updating || loading) && <Loading />}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          className={classes.button}
          color="primary"
          form="addGroupMember"
          type="reset"
          onClick={closeModal}
          disabled={loading}
        >
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          color="primary"
          form="addGroupMember"
          type="submit"
          disabled={!memberOptions || loading}
        >
          {t('add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
