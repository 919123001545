export const OWNER_ROLE = 'OWNER';
export const NOT_AVAILABLE_USER_UUID = '00000000-0000-4000-89ab-000000000000';
export const KEYCLOAK_ADMIN_USER_UUID = '8f9f4a59-30ea-4e9e-9589-ca5af0a836a8';

export const AvailableTeamMembers = (hospital) => hospital.teamMembers.filter((m) => {
  if (m.user.uuid === NOT_AVAILABLE_USER_UUID) {
    return null;
  }
  if (m.user.uuid !== hospital.owner && m.user.uuid === KEYCLOAK_ADMIN_USER_UUID) {
    return null;
  }
  return m;
});

export const AvailableHospitalUsers = (hospital, keycloakUsersOnly = true) => {
  const staff = hospital && hospital.teamMembers && hospital.teamMembers.filter((m) => {
    if (keycloakUsersOnly && m.user.uuid === NOT_AVAILABLE_USER_UUID) return null;
    if (m.user.uuid !== hospital.ownerUser.uuid && m.user.uuid === KEYCLOAK_ADMIN_USER_UUID) return null;
    if (m.user.uuid !== hospital.ownerUser.uuid) return m;
    let su = m;
    su.role = OWNER_ROLE;
    su.noAction = true;
    return su;
  });

  return staff && staff.length && (staff.find((s) => s.role === OWNER_ROLE)
    ? staff
    : [{
      uuid: hospital.uuid,
      role: OWNER_ROLE,
      createdAt: hospital.createdAt,
      user: hospital.ownerUser,
      noAction: true,
    }, ...staff]
  );
};

export default AvailableHospitalUsers;
