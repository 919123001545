import React from 'react';

import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';

import { Card } from '../styled/Card';
import { GroupMemberCardWrapper } from './styled/GroupMemberCardWrapper';
import { CollapsedCardWrapper } from '../styled/CollapsedCardWrapper';

import { AvatarUI } from '../../../componentsUI/Avatar';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  empty: {
    fontSize: '1.1875rem',
    color: theme.palette.primary.light,
  },
}));

export const GroupMemberCard = ({ groupMember, isChild }) => {
  const content = (
    <GroupMemberCardWrapper>
      <Box className="member">
        <AvatarUI user={groupMember.user} size={35} />
        <Box className="description">
          <Typography variant="body2">{`${groupMember.user.name} ${groupMember.user.surname}`}</Typography>
          <Typography variant="body2">{`${groupMember.user.email}`}</Typography>
        </Box>
      </Box>
    </GroupMemberCardWrapper>
  );

  return (
    <>
      {isChild
        ? <CollapsedCardWrapper>{content}</CollapsedCardWrapper>
        : <Card>{content}</Card>}
    </>
  );
};

export const NoMembers = () => {
  const { t } = useTranslation();
  const styles = useStyles();

  return (
    <Box>
      <Typography component="span" className={styles.empty}>{t('group.no.members')}</Typography>
    </Box>
  );
};
