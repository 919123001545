import styled from 'styled-components';

export const GroupMemberCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;

  .member {
    display: flex;
    align-items: center;
    & > .description {
      margin-left: 16px;
      & > :nth-child(2) {
        font-size: 0.875em;
        color: #999;
      }
    }
  }
  
  .member > .description{
    
  }

`;
